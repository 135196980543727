import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Page not Found :(</h1>
    <Button>Home</Button>
  </Layout>
)

export default NotFoundPage
